<template>
  <v-row class="pa-0 ma-0">
    <v-col v-if="!loaded">
      <div class="text-center">
        <v-progress-circular indeterminate color="primary" :size="70" :width="7"></v-progress-circular>
      </div>
    </v-col>
    <v-col sm="6" v-if="loaded">
      <div class=patientnumberdiv>
        <a class="patienttitle">Total patients: {{ totalPatients }}</a>
      </div>
      <v-row class="dashbox">
        <v-col sm="12" class="dashtitle pt-1 pb-1">Scan Date Range</v-col>
        <v-col sm="6" class="pt-1">
          <v-menu ref="startDateMenu" v-model="startDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="formattedStart" label="Start Date" readonly v-bind="attrs" v-on="on" prepend-icon="mdi-calendar">
                <template v-slot:append v-if="selectedStartDate">
                  <v-icon color="red lighten-2" @click="clearDate('start')">mdi-close-box-outline </v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker v-model="selectedStartDate" @input="startDateMenu=false;histBarClicked()" no-title scrollable></v-date-picker>
          </v-menu>
        </v-col>
        <v-col sm="6" class="pt-1">
          <v-menu ref="endDateMenu" v-model="endDateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
            min-width="auto">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="formattedEnd" label="End Date" readonly v-bind="attrs" v-on="on" prepend-icon="mdi-calendar">
                <template v-slot:append v-if="selectedEndDate">
                  <v-icon color="red lighten-2" @click="clearDate('end')">mdi-close-box-outline </v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker v-model="selectedEndDate" @input="endDateMenu=false;histBarClicked()" no-title scrollable></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row class="dashbox">
        <v-col sm="12" class="dashtitle pt-1 pb-1">Segmental Data</v-col>
        <v-col sm="12" class="pt-1">
           <v-checkbox v-model="displaySegmental" label="Display Segmental Data"  @change="histBarClicked()"></v-checkbox>
        </v-col>
        <v-col sm="12" class="pt-1" v-show="displaySegmental" >
          <LVmap />
        </v-col>
      </v-row>
      <div v-for="(tx,i) in histGroups" :key="i">
        <DashHist :hist="tx" @histBarClicked="histBarClicked" />
      </div>
    </v-col>
    <v-col sm="6" v-if="loaded">
      <div class="patientnumberdiv">
        <a class="patienttitle">Selected patients: {{ totalFilteredPatients }} </a>
      </div>
      <MBFbars />
      <v-data-table :headers="tableHeaders" :items="filteredAbstracts" :items-per-page="10" item-key="sk" class="elevation-1">
        <template v-slot:[`item.sk`]="{ item }">
          {{ item.sk.startsWith('parsed#') ? item.sk.substring(7) : item.sk }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon color="primary" @click="downloadFile(item)">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>

</template>

<script>
import DashHist from '@/components/DashHist.vue';
import MBFbars from '@/components/MBFbars.vue';
import LVmap from '../components/LVmap.vue';
import { Storage } from 'aws-amplify';
import { dataBus } from '@/main';

export default {
  name: 'Dashboard',
  components: {
    DashHist,
    MBFbars,
    LVmap,
  },
  props: [],
  data() {
    return {
      scanbars: [],
      filteredAbstracts: [],
      selectedStartDate: null,
      startDateMenu: false,
      selectedEndDate: null,
      endDateMenu: false,
      displaySegmental: false,
      tableHeaders: [
        { text: 'Site', value: 'site' },
        { text: 'Filename', value: 'sk' },
        { text: 'Scan Date', value: 'scanDate' },
        { text: 'Download', value: 'actions', sortable: false },
      ],
      arteryData: [
        { attributeName: 'restLAD', scanType: 'Rest', segment: 'LAD'},
        { attributeName: 'restLCX', scanType: 'Rest', segment: 'LCX'},
        { attributeName: 'restRCA', scanType: 'Rest', segment: 'RCA'},
        { attributeName: 'restGlobal', scanType: 'Rest', segment: 'Global'},
        { attributeName: 'stressLAD', scanType: 'Stress', segment: 'LAD'},
        { attributeName: 'stressLCX', scanType: 'Stress', segment: 'LCX'},
        { attributeName: 'stressRCA', scanType: 'Stress', segment: 'RCA'},
        { attributeName: 'stressGlobal', scanType: 'Stress', segment: 'Global'},
        { attributeName: 'cfrLAD', scanType: 'MFR', segment: 'LAD'},
        { attributeName: 'cfrLCX', scanType: 'MFR', segment: 'LCX'},
        { attributeName: 'cfrRCA', scanType: 'MFR', segment: 'RCA'},
        { attributeName: 'cfrGlobal', scanType: 'MFR', segment: 'Global'},
      ],
    };
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split('-');
      return `${month}/${day}/${year}`;
    },
    clearDate(dateType) {
      if (dateType === 'start') {
        this.selectedStartDate = null;
      } else {
        this.selectedEndDate = null;
      }
      this.histBarClicked();
    },
    downloadFile(item) {
      const filename = item.sk.substring(7);
      Storage.get(item.site + '/' + filename, { download: true }).then((result) =>
        this.downloadBlob(result.Body, filename)
      );
    },
    downloadBlob(blob, filename) {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename || 'download';
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url);
          a.removeEventListener('click', clickHandler);
        }, 150);
      };
      a.addEventListener('click', clickHandler, false);
      a.click();
      return a;
    },
    histBarClicked() {
      this.filteredAbstracts = this.studyAbstracts.filter(this.filterStudy, this);

      // histogram the filtered set of histograms
      this.histGroups.forEach((group) => {
        group.sortStudies(this.filteredAbstracts, true);
      });
      // create the MBF bars
      this.setMBFbars(this.filteredAbstracts);
      dataBus.$emit('filtersUpdated', this.scanbars);
    },

    filterStudy(study) {
      let include_study = true;
      for (let histGroup of this.histGroups) {
        if (!histGroup.applyFilter(study)) {
          include_study = false;
          break;
        }
      }
      if (
        include_study &&
        this.selectedStartDate &&
        (!study.scanDate || new Date(this.selectedStartDate) > new Date(study.scanDate))
      ) {
        include_study = false;
      }
      if (
        include_study &&
        this.selectedEndDate &&
        (!study.scanDate || new Date(this.selectedEndDate) < new Date(study.scanDate))
      ) {
        include_study = false;
      }
      if( this.displaySegmental && !study.restSegmental ) {
        include_study = false;
      }
      return include_study;
    },

    setMBFbars(studyData) {
      this.scanbars = [];
      if (studyData.length > 0) {
        if( !this.displaySegmental ) {

          for( const artery of this.arteryData ) {
            this.scanbars.push(
              this.minmaxmean(
                studyData
                  .filter((d) => d[artery.attributeName]) //not null check
                  .map((d) => d[artery.attributeName]),
                artery.scanType,
                artery.segment,
                null
              )
            );
          }
        } else {
          
          for( const segment of this.selectedSegments ) {
            this.scanbars.push(
              this.minmaxmean(
                studyData
                  .filter((d) => d.restSegmental[segment.segment-1]) //not null check
                  .map((d) => d.restSegmental[segment.segment-1]),
                "Rest",
                segment.art,
                segment.segment
              )
            );
            this.scanbars.push(
              this.minmaxmean(
                studyData
                  .filter((d) => d.stressSegmental[segment.segment-1]) //not null check
                  .map((d) => d.stressSegmental[segment.segment-1]),
                "Stress",
                segment.art,
                segment.segment
              )
            );
            this.scanbars.push(
              this.minmaxmean(
                studyData
                  .filter((d) => d.cfrSegmental[segment.segment-1]) //not null check
                  .map((d) => d.cfrSegmental[segment.segment-1]),
                "MFR",
                segment.art,
                segment.segment
              )
            );
          }
        }
      }
    },
    minmaxmean(dx, scan, art, index) {
      dx = dx.sort(function (a, b) {
        return a - b;
      });

      let lx = dx.length;
      let min = 0;
      let max = 0;
      let mean = 0;
      let midpt = 0;
      if (lx == 1) {
        min = dx[0];
        max = dx[0];
        mean = dx[0];
      } else if (lx > 1) {
        let eo = dx.length % 2;
        min = dx[0];
        max = dx[dx.length - 1];
        midpt = Math.floor(dx.length / 2);
        if (eo == 1) {
          mean = dx[midpt];
        } else {
          mean = (dx[midpt] + dx[midpt - 1]) / 2;
        }
      }
      return {
        scan: scan,
        art: art,
        index: index,
        min: min,
        max: max,
        mean: mean,
      };
    },
  },
  computed: {
    histGroups() {
      return this.$store.getters.currentAbstractHistograms;
    },
    studyAbstracts() {
      return this.$store.getters.currentStudyAbstracts;
    },
    loaded() {
      return this.$store.getters.abstractsRetrieved;
    },
    totalPatients() {
      return this.$store.getters.currentStudyAbstracts.length;
    },
    totalFilteredPatients() {
      return this.filteredAbstracts.length;
    },
    formattedStart() {
      return this.formatDate(this.selectedStartDate);
    },
    formattedEnd() {
      return this.formatDate(this.selectedEndDate);
    },
    selectedSegments() {
      return this.$store.getters.selectedSegments;
    }
  },
  watch: {
    selectedSegments() {
      this.histBarClicked();
    }
  },
  created() {
    this.$store.dispatch('getStudyAbstracts');
  },
  mounted() {
    this.histBarClicked();
  },
  beforeDestroy() {
    dataBus.$off();
  },
};
</script>

<style scoped>
.dashbox {
  margin: 16px 0 0 18px; /*  top  right  bottom  left   */
}
.patienttitle {
  color: white;
  font-size: 24px;
  margin-top: 8px;
  margin-bottom: 0;
  margin-left: 46px;
  font-weight: semi-bold;
}
.patientnumberdiv {
  background-color: #4b6076;
  margin-left: 18px;
  margin-top: 6px;
}
.dashbox {
  background-color: #e7e6e6;
  border-radius: 12px;
}
.dashtitle {
  font-weight: semi-bold;
  color: #4b6076;
  font-size: 22px;
  /* margin: 10px 0 0 18px; */
}
</style>
