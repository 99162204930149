<template>
  <div class="LVMcanvas"></div>
</template>

<script>
import * as d3 from 'd3';
import { getColor } from '@/classes/Color.js';
export default {
  name: 'LVmap',
  data() {
    return {
      radiusStep: 35,
      margin: { top: 20, right: 50, bottom: 20, left: 100 },
      svg: null,
      graph: null,
      color: null,
      zones: [
        { name: 'RCA', x: 100, y: 280, selected: false },
        { name: 'LCX', x: 400, y: 0, selected: false },
        { name: 'LAD', x: 110, y: 40, selected: false },
      ],

      lvdata: [
        {
          segment: 1,
          zone: 'Basal',
          slice: 'anterior',
          art: 'LAD',
          ring: 3,
          theta_min: -30,
          theta_max: 30,
          selected: false,
        },
        {
          segment: 2,
          zone: 'Basal',
          slice: 'anteroseptal',
          art: 'LAD',
          ring: 3,
          theta_min: -90,
          theta_max: -30,
          selected: false,
        },
        {
          segment: 3,
          zone: 'Basal',
          slice: 'inferoseptal',
          art: 'RCA',
          ring: 3,
          theta_min: 210,
          theta_max: 270,
          selected: false,
        },
        {
          segment: 4,
          zone: 'Basal',
          slice: 'inferior',
          art: 'RCA',
          ring: 3,
          theta_min: 150,
          theta_max: 210,
          selected: false,
        },
        {
          segment: 5,
          zone: 'Basal',
          slice: 'inferolateral',
          art: 'LCX',
          ring: 3,
          theta_min: 90,
          theta_max: 150,
          selected: false,
        },
        {
          segment: 6,
          zone: 'Basal',
          slice: 'anterolateral',
          art: 'LCX',
          ring: 3,
          theta_min: 30,
          theta_max: 90,
          selected: false,
        },
        {
          segment: 7,
          zone: 'Mid',
          slice: 'anterior',
          art: 'LAD',
          ring: 2,
          theta_min: -30,
          theta_max: 30,
          selected: false,
        },
        {
          segment: 8,
          zone: 'Mid',
          slice: 'anteroseptal',
          art: 'LAD',
          ring: 2,
          theta_min: -90,
          theta_max: -30,
          selected: false,
        },
        {
          segment: 9,
          zone: 'Mid',
          slice: 'inferoseptal',
          art: 'RCA',
          ring: 2,
          theta_min: 210,
          theta_max: 270,
          selected: false,
        },
        {
          segment: 10,
          zone: 'Mid',
          slice: 'inferior',
          art: 'RCA',
          ring: 2,
          theta_min: 150,
          theta_max: 210,
          selected: false,
        },
        {
          segment: 11,
          zone: 'Mid',
          slice: 'inferolateral',
          art: 'LCX',
          ring: 2,
          theta_min: 90,
          theta_max: 150,
          selected: false,
        },
        {
          segment: 12,
          zone: 'Mid',
          slice: 'anterolateral',
          art: 'LCX',
          ring: 2,
          theta_min: 30,
          theta_max: 90,
          selected: false,
        },
        {
          segment: 13,
          zone: 'Apical',
          slice: 'anterior',
          art: 'LAD',
          ring: 1,
          theta_min: -45,
          theta_max: 45,
          selected: false,
        },
        {
          segment: 14,
          zone: 'Apical',
          slice: 'septal',
          art: 'LAD',
          ring: 1,
          theta_min: 225,
          theta_max: 315,
          selected: false,
        },
        {
          segment: 15,
          zone: 'Apical',
          slice: 'inferior',
          art: 'RCA',
          ring: 1,
          theta_min: 135,
          theta_max: 225,
          selected: false,
        },
        {
          segment: 16,
          zone: 'Apical',
          slice: 'lateral',
          art: 'LCX',
          ring: 1,
          theta_min: 45,
          theta_max: 135,
          selected: false,
        },
        {
          segment: 17,
          zone: 'Apical',
          slice: 'apical',
          art: 'LAD',
          ring: 0,
          theta_min: 0,
          theta_max: 360,
          selected: false,
        },
        {
          segment: 18,
          zone: 'global',
          slice: 'global',
          art: 'GLO',
          ring: -1,
          theta_min: -90,
          theta_max: 270,
          selected: false,
        },
      ],
    };
  },
  methods: {
    setupGraph() {
      // calculate the canvas size
      const dims = {
        width: 8 * this.radiusStep + this.margin.left + this.margin.right,
        height: 8 * this.radiusStep + this.margin.bottom + this.margin.top,
      };

      // calculate the coordinates of the center
      const cent = {
        x: 4 * this.radiusStep + this.margin.left,
        y: 4 * this.radiusStep + this.margin.top,
      };
      this.zones[1].y = cent.y + 5;

      this.svg = d3.select('.LVMcanvas').append('svg').attr('width', dims.width).attr('height', dims.height);

      // create a group to contain the graph elements
      this.graph = this.svg.append('g').attr('transform', `translate(${cent.x}, ${cent.y})`);

      // define the arc-generation function
      var PI = Math.PI;
      const arcPath = d3
        .arc()
        .outerRadius((d) => {
          return (d.ring + 1) * this.radiusStep;
        })
        .innerRadius((d) => {
          return d.ring * this.radiusStep;
        })
        .startAngle((d) => {
          return (d.theta_min * PI) / 180;
        })
        .endAngle((d) => {
          return (d.theta_max * PI) / 180;
        });
      const apicalCircle = this.lvdata.filter((data) => data.ring >= 0);
      const paths = this.graph.selectAll('path').data(apicalCircle);

      // // append the enter selection
      paths
        .enter()
        .append('path')
        .attr('class', 'arc')
        .attr('d', arcPath)
        .attr('stroke', 'DimGray')
        .attr('stroke-width', 1)
        .attr('fill', (d) => getColor(d.art, null));

      // create and plot labels
      const labels = this.graph.selectAll('text').data(apicalCircle);
      labels
        .enter()
        .append('text')
        .each(function (d) {
          var centroid = arcPath.centroid(d);
          d3.select(this)
            .attr('x', centroid[0])
            .attr('y', centroid[1] + 5)
            .text(d.segment)
            .attr('font-weight', 'lighter')
            .attr('text-anchor', 'middle');
        });

      const zlabels = this.svg.selectAll('.segment').data(this.zones);
      zlabels
        .enter()
        .append('text')
        .attr('x', (d) => d.x)
        .attr('y', (d) => d.y)
        .text((d) => d.name)
        .attr('fill', '#aaa')
        .on('mouseover', this.labelMouseOver)
        .on('mouseout', this.labelMouseOut)
        .on('click', this.labelClicked);

      // add events
      this.graph
        .selectAll('path')
        .on('mouseover', this.handleMouseOver)
        .on('mouseout', this.handleMouseOut)
        .on('click', this.handleClick);
    },

    handleMouseOver(evt, data) {
      d3.select(evt.currentTarget)
        .style('cursor', 'pointer')
        .transition('changeSliceFill')
        .duration(100)
        .attr('fill', getColor(data.art, 0.2));
    },

    handleMouseOut(evt) {
      d3.select(evt.currentTarget).style('cursor', 'default');
      this.refreshFontAndColor();
    },
    handleClick(evt, data) {
      data.selected = !data.selected;
      this.$store.dispatch(
        'setSelectedSegments',
        this.lvdata.filter((d) => d.selected)
      );
      this.refreshFontAndColor();
    },
    labelMouseOver(evt) {
      d3.select(evt.currentTarget).style('cursor', 'pointer');
    },

    labelMouseOut(evt) {
      d3.select(evt.currentTarget).style('cursor', 'default');
    },
    labelClicked(evt, data) {
      data.selected = !data.selected;
      this.lvdata
        .filter((item) => item.art === data.name)
        .forEach((item) => {
          item.selected = data.selected;
        });
      this.$store.dispatch(
        'setSelectedSegments',
        this.lvdata.filter((d) => d.selected)
      );
      this.refreshFontAndColor();
    },
    refreshFontAndColor() {
      this.graph
        .selectAll('path')
        .transition()
        .duration(100)
        .attr('fill', (d) => getColor(d.art, d.selected ? -0.14 : null));
      this.graph
        .selectAll('text')
        .transition()
        .duration(100)
        .attr('font-weight', (d) => (d.selected ? 'bold' : 'lighter'));
    },
  },
  mounted() {
    this.setupGraph();
  },
};
</script>


<style>
.segment {
  background-color: green;
  color: purple;
}
</style>
