<template>
  <div class="dashbox">
    <p class="dashtitle">{{hist.histname | capitalize }}</p>
    <div class="Dashcanvas">
      <div :id="hist.id"></div>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import { dataBus } from "@/main";

export default {
  name: "DashHist",
  props: ["hist"],
  data() {
    return {
      svg: null,
      graph: null,
      graphWidth: null,
      graphHeight: null,
      xAxisGroup: null,
      yAxisGroup: null,
      barHeight: 30,
      barGap: 3,
      nbins: null,
      xa: null,
      ya: null,
      divwidth: 400,
      margin: { top: 5, right: 50, bottom: 20, left: 16 },
    };
  },
  methods: {
    setupHistogram() {
      this.nbins = this.hist.bars.length;
      let divheight =
        this.nbins * (this.barHeight + this.barGap) +
        this.margin.bottom +
        this.margin.top +
        this.barGap;

      // create the SVG container for the histogram
      this.svg = d3
        .select("#" + this.hist.id)
        .append("svg")
        .attr("width", this.divwidth + 140) //add 100 for label
        .attr("height", divheight);

      this.graphWidth = this.divwidth - this.margin.left - this.margin.right;
      this.graphHeight =
        this.nbins * (this.barHeight + this.barGap) + this.barGap;

      this.graph = this.svg
        .append("g")
        .attr(
          "transform",
          `translate(${this.margin.left}, ${this.margin.top})`
        );

      this.xAxisGroup = this.graph
        .append("g")
        .attr("transform", `translate(0, ${this.graphHeight})`);
      this.yAxisGroup = this.graph.append("g");

      // create the linear scale for the vertical axis
      this.ya = d3.scaleLinear().range([this.graphHeight, 0]); // range of output values (pixels)

      // create the linear scale for the horizontal axis
      this.xa = d3.scaleLinear().range([0, this.graphWidth]);

      this.plotHistogram();
    },
    plotHistogram() {
      let xmax = 0;
      for( let bar of this.hist.bars ) {
        if( bar.amplitude > xmax ) {
          xmax = bar.amplitude
        }
      }
      this.xa.domain([0, xmax * 1.1]);
      this.ya.domain([0, this.nbins]);

      // create and call the axes
      const xAxis = d3.axisBottom(this.xa).ticks(4);
      const yAxis = d3.axisLeft(this.ya).ticks(0);

      //
      //  main histogram bars
      //

      // join the data to some rectangles
      const rects = this.graph.selectAll("rect").data(this.hist.bars);

      // remove the exit selection
      rects.exit().remove();

      // add attrs to rects already in DOM
      rects
        .attr("width", (d) => this.xa(d.amplitude))
        .attr("height", () => this.barHeight)
        .attr("fill", (d) => (d.includeStudy ? "#8CC342" : "#c5c4c4"))
        .attr("x", 0)
        .attr("y", (d, i) => this.ya(i + 1) + this.barGap);

      // append rects and attrs to the enter selection
      rects
        .enter()
        .append("rect")
        .attr("width", (d) => this.xa(d.amplitude))
        .attr("height", () => this.barHeight)
        .attr("fill", (d) => (d.includeStudy ? "#8CC342" : "#c5c4c4"))
        .attr("x", 0)
        .attr("y", (d, i) => this.ya(i + 1) + this.barGap);

      this.graph.selectAll("rect").on("click", (evt, d) => {
        d.includeStudy = !d.includeStudy;
        this.$emit("histBarClicked");
      });

      //
      // filtered data markers
      //
      const circles = this.graph
        .selectAll(".filtercircle")
        .data(this.hist.bars);
      circles.exit().remove();
      circles
        .attr("class", "filtercircle")
        .attr("cx", (d) => this.xa(d.filteredQuant))
        .attr("cy", (d, i) => this.ya(i + 1) + this.barHeight)
        .attr("r", 3)
        .style("fill", "black")
        .style("opacity", (d) => this.circleopacity(d));
      circles
        .enter()
        .append("circle")
        .attr("class", "filtercircle")
        .attr("cx", (d) => this.xa(d.filteredQuant))
        .attr("cy", (d, i) => this.ya(i + 1) + this.barHeight)
        .attr("r", 3)
        .style("fill", "black")
        .style("opacity", (d) => this.circleopacity(d));

      //
      // histogram bar labels
      //

      // add category labels to the bars
      const barlabels = this.graph.selectAll(".catbars").data(this.hist.bars);
      barlabels
        .enter()
        .append("text")
        .attr("class", "catbars")
        .attr("x", 375)
        .attr(
          "y",
          (d, i) => this.ya(i + 1) + this.barGap + this.barHeight / 2 + 5
        )
        .text((d) => d.label)
        .attr("fill", "#4B6076");

      // add numeric labels to the bars
      const numberlabels = this.graph
        .selectAll(".numbars")
        .data(this.hist.bars);
      numberlabels.exit().remove();
      numberlabels
        .attr("class", "numbars")
        .attr("x", (d) => this.xa(d.amplitude) + 8)
        .attr(
          "y",
          (d, i) => this.ya(i + 1) + this.barGap + this.barHeight / 2 + 5
        )
        .text((d) => this.numberlabel(d))
        .attr("fill", "black");
      numberlabels
        .enter()
        .append("text")
        .attr("class", "numbars")
        .attr("x", (d) => this.xa(d.amplitude) + 8)
        .attr(
          "y",
          (d, i) => this.ya(i + 1) + this.barGap + this.barHeight / 2 + 5
        )
        .text((d) => this.numberlabel(d))
        .attr("fill", "black");

      this.xAxisGroup.call(xAxis);
      this.yAxisGroup.call(yAxis);
    },
    numberlabel(d) {
      if (d.filteredQuant == 0) {
        return "";
      } else if (d.filteredQuant == d.amplitude) {
        return d.amplitude;
      } else {
        return d.filteredQuant + " / " + d.amplitude;
      }
    },
    circleopacity(d) {
      if (d.filteredQuant == 0) {
        return 0.0;
      } else if (d.filteredQuant == d.amplitude) {
        return 0.0;
      } else {
        return 1.0;
      }
    },
  },
  mounted() {
    dataBus.$on("filtersUpdated", () => {
      this.plotHistogram();
    });
    this.setupHistogram();
    this.$emit("histBarClicked"); // force an update of the MBF bars
  },
  beforeDestroy() {
    this.graph = null;
    this.svg = null;
    dataBus.$off();
  },
};
</script>

<style scoped>
.numbars {
  color: red;
}
.dashcanvas {
  background-color: indianred;
}
.dashbox {
  background-color: #e7e6e6;
  border-radius: 12px;
}
.dashtitle {
  font-weight: semi-bold;
  color: #4b6076;
  font-size: 22px;
  margin: 10px 0 0 18px;
}
</style>
