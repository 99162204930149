<template>
  <div class="MBFcanvas">
  </div>
</template>

<script>
import * as d3 from "d3";
import { dataBus } from "@/main";
import { getColor } from "@/classes/Color.js";

export default {
  name: "MBFbars",
  components: {},
  props: [],
  data() {
    return {
      bars: [],
      svg: null,
      graphWidth: null,
      graphHeight: null,
      graph: null,
      xa: null,
      ya: null,
      dims: { width: 600, height: 440 },
      margin: { top: 10, right: 10, bottom: 30, left: 100 },
    };
  },
  methods: {
    setupChart() {
      this.svg = d3
        .select(".MBFcanvas")
        .append("svg")
        .attr("width", this.dims.width)
        .attr("height", this.dims.height);

      this.graphWidth = this.dims.width - this.margin.left - this.margin.right;
      this.graphHeight =
        this.dims.height - this.margin.bottom - this.margin.top;

      this.graph = this.svg
        .append("g")
        .attr(
          "transform",
          `translate(${this.margin.left}, ${this.margin.top})`
        );

      this.xAxisGroup = this.graph
        .append("g")
        .attr("transform", `translate(0, ${this.graphHeight})`);

      // create the linear scale for the vertical axis
      this.ya = d3.scaleLinear().range([0, this.graphHeight]);

      // create the linear scale for the horizontal axis
      this.xa = d3.scaleLinear().range([0, this.graphWidth]);

      if (this.bars && this.bars.length > 0) this.plotChart();
    },
    roundToString(val) {
      if( val === 0 ) {
        return '0';
      }
      return '' + (Math.round(val * 100) / 100).toFixed(2); //round to 2 decimal places
    },
    getTitle(data) {
      return "Min: " + this.roundToString(data.min) + "; Max: " + this.roundToString(data.max) + "; Median:" + this.roundToString(data.mean);
    },
    getBarLabel(data) {
      if( data.index ) {
        return data.index + "(" + data.art + ") " + data.scan;
      } 
      return data.art + " " + data.scan
    },
    plotChart() {
      // figure out the horizontal scale range
      const xmax = d3.max(
        this.bars.map((d) => {
          return d.max;
        })
      );

      const xmin = d3.min(
        this.bars.map((d) => {
          return d.min;
        })
      );
      const scaleMargin = (xmax - xmin) * 0.2;

      this.ya.domain([0, this.bars.length]);
      this.xa.domain([xmin - scaleMargin / 4, xmax + scaleMargin]);

      const xAxis = d3.axisBottom(this.xa);
      const barHeight = this.graphHeight / this.bars.length - 3;

      // draw the colored bars
      const rects = this.graph.selectAll("rect").data(this.bars);
      //remove all previous "title" elements that will be appended
      rects.selectAll('title').remove();
      rects.exit().remove();

      // append rects and attrs to the enter selection
      rects
        .enter()
        .append("rect")
        .merge(rects)
        .attr("width", (d) => this.xa(d.max) - this.xa(d.min))
        .attr("height", barHeight)
        .attr("fill", (d) => getColor(d.art, null))
        .attr("x", (d) => this.xa(d.min))
        .attr("y", (d, i) => this.ya(i))
        .append("title")
        .text((d) => this.getTitle(d));

      // draw the mean lines
      const lines = this.graph.selectAll(".meanline").data(this.bars);
      // remove the exit selection
      lines.exit().remove();
      lines
        .enter()
        .append("line")
        .attr("class", "meanline")
        .style("stroke", "black")
        .attr("stroke-width", 3)
        .merge(lines) //after the "merge" are properties set for both new and existing elements
        .attr("x1", (d) => this.xa(d.mean))
        .attr("y1", (d, i) => this.ya(i))
        .attr("x2", (d) => this.xa(d.mean))
        .attr("y2", (d, i) => this.ya(i) + barHeight)

      this.xAxisGroup.call(xAxis);

      // add bar labels
      const zlabels = this.svg.selectAll(".segment").data(this.bars);
      zlabels.exit().remove();
      zlabels
        .enter()
        .append("text")
        .attr("class", "segment")
        .attr("fill", "#aaa")
        .attr("x", 5)
        .merge(zlabels)  //after the "merge" are properties set for both new and existing elements
        .attr("y", (d, i) => this.ya(i) + barHeight / 2 + this.margin.top + 5)
        .text((d) => this.getBarLabel(d));
    },
  },
  mounted() {
    dataBus.$on("filtersUpdated", (scanbars) => {
      this.bars = scanbars;
      this.plotChart();
    });
    this.setupChart();
  },
  beforeDestroy() {
    this.graph = null;
    this.svg = null;
    dataBus.$off();
  },
};
</script>

<style scoped>
.MBFcanvas {
  margin-top: 8px;
  margin-left: 16px;
}
</style>
