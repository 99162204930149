/**
 * Utility method for getting colors for arteries by artery name
 * @param {*} arteryName artery
 * @param {*} lightenFactor factor to lighten/darken the color; negative values darken
 * @returns 
 */
export function getColor(arteryName, lightenFactor) {
  var rgb = '#b3de69';
  switch(arteryName) {
    case 'LAD':
      rgb = '#80b1d3';
      break;
    case 'LCX':
      rgb = '#fb8072';
      break;
    case 'RCA':
      rgb = '#fdb462';
      break;
  }
  if( lightenFactor ) {
    rgb = lighten(rgb, lightenFactor);
  }
  return rgb;
}
/**
 * Lighten provided color by some factor. This function was 
 * @param {*} colorRGB hex rbg value of color
 * @param {*} luminosity factor to lighten/darken the color; negative values darken
 * @returns 
 */
export function lighten(colorRGB, luminosity) {
  // validate hex string
  colorRGB = String(colorRGB).replace(/[^0-9a-f]/gi, '');
  if (colorRGB.length < 6) {
    colorRGB = colorRGB[0]+ colorRGB[0]+ colorRGB[1]+ colorRGB[1]+ colorRGB[2]+ colorRGB[2];
  }
  luminosity = luminosity || 0;

  // convert to decimal and change luminosity
  var newColor = "#", c, i, black = 0, white = 255;
  for (i = 0; i < 3; i++) {
    c = parseInt(colorRGB.substr(i*2,2), 16);
    c = Math.round(Math.min(Math.max(black, c + (luminosity * white)), white)).toString(16);
    newColor += ("00"+c).substring(c.length);
  }
  return newColor; 
}
